import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)

export const enum OPTION_STATUS {
  NOT_STARTED_EXERCISE = 'NOT_STARTED_EXERCISE',
  EXERCISE = 'EXERCISE',
  EXPIRED = 'EXPIRED',
}
export type OptionStatusValues = EnumAsUnion<typeof OPTION_STATUS>

/**
 * @description Determine the current option status based on the exercise time
 * @param {MaybeRefOrGetter<string>} timestamp Unit in seconds
 */
export function useOptionStatus(timestamp: MaybeRefOrGetter<string>) {
  const status = computed<OPTION_STATUS>(() => {
    const _timestamp = Number(toValue(timestamp) + '000')
    const now = Date.now()

    if (dayjs(now).isSameOrAfter(dayjs(_timestamp))) {
      if (dayjs(now).isSameOrAfter(dayjs(_timestamp).add(1, 'day'))) {
        return OPTION_STATUS.EXPIRED
      } else {
        return OPTION_STATUS.EXERCISE
      }
    } else {
      return OPTION_STATUS.NOT_STARTED_EXERCISE
    }
  })
  const canBuyNow = computed(() => {
    return status.value === OPTION_STATUS.NOT_STARTED_EXERCISE
  })
  const canUnwindNow = computed(() => {
    return status.value === OPTION_STATUS.NOT_STARTED_EXERCISE
  })
  const canExerciseNow = computed(() => {
    return status.value === OPTION_STATUS.EXERCISE
  })
  const canCollectNow = computed(() => {
    return status.value === OPTION_STATUS.EXPIRED
  })
  const isExpiringSoon = computed(() => {
    const _timestamp = Number(toValue(timestamp) + '000')
    const now = Date.now()

    return (
      dayjs(now).isSameOrAfter(dayjs(_timestamp).subtract(1, 'hour')) &&
      dayjs(now).isBefore(dayjs(_timestamp))
    )
  })
  return {
    status,
    canBuyNow,
    canUnwindNow,
    canExerciseNow,
    canCollectNow,
    isExpiringSoon,
  }
}
